import React from 'react';
import './App.scss';
import Home from './pages/home';

function App() {

  return (

    <Home />
  );
}

export default App;
